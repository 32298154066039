import request from "@/utils/request";

/**
 * Create schedule location.
 *
 * @param {object} data
 * @param {string} data.name_zh
 * @param {string} data.name_en
 */
export function createScheduleLocation(data) {
  return request({
    url: "api/v1/schedules-locations",
    method: "POST",
    data,
  });
}

/**
 * Get schedule's locations.
 */
export function getScheduleLocations() {
  return request({
    url: "api/v1/schedules-locations",
    method: "GET",
  });
}

/**
 * Get schedule's location with pagination.
 *
 * @param {object} [params]
 * @param {string} params.limit
 * @param {object} params.page
 */
export function getScheduleLocationsPagination(params) {
  return request({
    url: "api/v1/schedules-locations/page",
    method: "GET",
    params,
  });
}

/**
 * Get schedule location.
 *
 * @param {number} schedule_location_id
 */
export function getScheduleLocation(schedule_location_id) {
  return request({
    url: `api/v1/schedules-locations/${schedule_location_id}`,
    method: "GET",
  });
}

/**
 * Update schedule location.
 *
 * @param {number} schedule_location_id
 * @param {object} data
 * @param {string} data.name_en
 * @param {string} data.name_zh
 */
export function updateScheduleLocation(schedule_location_id, data) {
  return request({
    url: `api/v1/schedules-locations/${schedule_location_id}`,
    method: "PATCH",
    data,
  });
}

/**
 * Delete schedule location.
 *
 * @param {number} schedule_location_id
 */
export function deleteScheduleLocation(schedule_location_id) {
  return request({
    url: `api/v1/schedules-locations/${schedule_location_id}`,
    method: "DELETE",
  });
}
