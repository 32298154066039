<template>
  <div class="detail-schedule-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Schedule' }"
        >檔期管理
      </el-breadcrumb-item>
      <el-breadcrumb-item>檔期內容</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <div class="flex-end" style="padding: 12px 0">
        <el-button type="info" @click="show_detail = !show_detail">{{
          !show_detail ? "內容展開" : "內容收起"
        }}</el-button>
      </div>
      <div
        class="content text-center"
        v-if="!show_detail"
        @click="show_detail = !show_detail"
      >
        內容過多，請點擊展開
      </div>
      <el-form :model="schedule_form" label-position="top" v-show="show_detail">
        <el-form-item label="中文名稱" prop="name_zh">
          <el-input
            v-model="schedule_form.name_zh"
            maxlength="256"
            show-word-limit
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="英文名稱" prop="name_en">
          <el-input
            v-model="schedule_form.name_en"
            maxlength="256"
            show-word-limit
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="開展日期" prop="start_date">
          <el-date-picker
            v-model="schedule_form.start_date"
            type="datetime"
            placeholder="請選擇開展日期"
            disabled
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="閉展日期" prop="end_date">
          <el-date-picker
            v-model="schedule_form.end_date"
            type="datetime"
            placeholder="請選擇閉展日期"
            disabled
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="展區" prop="schedule_location_id">
          <el-select
            v-model="schedule_form.schedule_location_id"
            :disabled="true"
          >
            <el-option
              v-for="{ schedule_location_id, name_zh } in scheduleLocations"
              :key="schedule_location_id"
              :value="schedule_location_id"
              :label="name_zh"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="啟用" prop="active">
          <el-radio
            v-model="schedule_form.active"
            :border="true"
            :label="true"
            disabled
            >啟用</el-radio
          >
          <el-radio
            v-model="schedule_form.active"
            :border="true"
            :label="false"
            disabled
            >停用</el-radio
          >
        </el-form-item>
        <el-form-item label="CF影片連結" prop="cf_video_links">
          <el-input
            v-for="(_, index) in schedule_form.cf_video_links"
            class="cf-input"
            :class="[schedule_form.cf_video_links.length > 1 ? 'gap' : '']"
            v-model="schedule_form.cf_video_links[index]"
            :key="index"
            disabled
          >
          </el-input>
        </el-form-item>
        <el-form-item label="備註" prop="note">
          <el-input
            type="textarea"
            v-model="schedule_form.note"
            maxlength="512"
            show-word-limit
            disabled
          ></el-input>
        </el-form-item>

        <el-form-item>
          <div class="flex-end">
            <el-button
              type="warning"
              @click="$router.push({ name: 'Schedule' })"
              >回主頁</el-button
            >
            <el-button type="primary" @click="handleRedirectToEditPage"
              >編輯</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </div>

    <schedule-activity-table
      :data="activities"
      :loading="loading.activities"
      @get:activities="handleGetActivities"
    ></schedule-activity-table>

    <schedule-work-table
      :data="works"
      :loading="loading.works"
      @get:works="handleGetWorks"
    ></schedule-work-table>

    <schedule-artist-table
      :data="artists"
      :loading="loading.artists"
      @get:artists="handleGetArtists"
    ></schedule-artist-table>

    <keyword-table
      :data="schedule_form.keywords"
      title="檔期關鍵字列表"
      :show-total="false"
      :create-method="createScheduleKeywords"
      :delete-method="deleteScheduleKeywords"
      @get:keywords="handleGetSchedule"
    ></keyword-table>
  </div>
</template>

<script>
import {
  getSchedule,
  getActivities,
  getWorks,
  getArtists,
  createScheduleKeywords,
  deleteScheduleKeywords,
} from "@/api/schedule";
import { getScheduleLocations } from "@/api/schedule-location";
import ScheduleArtistTable from "./components/ScheduleArtistTable";
import ScheduleActivityTable from "./components/ScheduleActivityTable";
import ScheduleWorkTable from "./components/ScheduleWorkTable";
import KeywordTable from "@/components/Keyword/KeywordTable.vue";

const MAXIMUM_LIMIT = 200;

export default {
  name: "DetailSchedule",
  components: {
    ScheduleArtistTable,
    ScheduleActivityTable,
    ScheduleWorkTable,
    KeywordTable,
  },
  data() {
    return {
      schedule_form: {
        name_zh: "",
        name_en: "",
        start_date: "",
        end_date: "",
        note: "",
        active: true,
        schedule_location_id: "",
        cf_video_links: [""],
        keywords: [],
      },
      artists: [],
      artists_params: {
        limit: MAXIMUM_LIMIT,
        page: 0,
      },
      works: [],
      works_params: {
        limit: MAXIMUM_LIMIT,
        page: 0,
      },
      activities: [],
      activities_params: {
        limit: MAXIMUM_LIMIT,
        page: 0,
      },
      loading: {
        activities: false,
        works: false,
        artists: false,
      },
      show_detail: false,
      createScheduleKeywords,
      deleteScheduleKeywords,
      scheduleLocations: [],
    };
  },
  computed: {
    scheduleId() {
      return this.$route.params.id;
    },
  },
  async created() {
    await this.handleGetScheduleLocation();
    await this.handleGetSchedule();
    await this.handleGetActivities();
    await this.handleGetWorks();
    await this.handleGetArtists();
  },
  methods: {
    async handleGetScheduleLocation() {
      this.scheduleLocations = await getScheduleLocations();
    },
    async handleGetSchedule() {
      const res = await getSchedule(this.scheduleId, false);

      Object.keys(this.schedule_form).forEach((key) => {
        if (res[key]) {
          if (key === "cf_video_links" && res[key].length === 0) return;
          this.schedule_form[key] = res[key];
        } else {
          if (key === "schedule_location_id") {
            if (res.schedule_location) {
              this.schedule_form.schedule_location_id =
                res.schedule_location.schedule_location_id;
            } else {
              this.schedule_form.schedule_location_id =
                this.scheduleLocations[0].schedule_location_id;
            }
          }
        }
      });
    },
    async handleGetActivities() {
      this.loading.activities = true;

      const { results } = await getActivities(
        this.scheduleId,
        this.activities_params
      );

      this.activities = results;

      this.loading.activities = false;
    },
    async handleGetWorks() {
      this.loading.works = true;

      const { results } = await getWorks(this.scheduleId, this.works_params);

      this.works = results;

      this.loading.works = false;
    },
    async handleGetArtists() {
      this.loading.artists = true;

      const { results } = await getArtists(
        this.scheduleId,
        this.artists_params
      );

      this.artists = results;

      this.loading.artists = false;
    },
    async handleRedirectToEditPage() {
      await this.$router.push({
        name: "EditSchedule",
        params: { id: this.scheduleId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-schedule-block {
  .content {
    margin: 12px 0;
    padding: 24px 0;
    border: 1px solid #606266;
    border-radius: 12px;
    color: var(--primary-gray-color);
    font-size: 14px;
    cursor: pointer;

    &:hover {
      border: 1px solid #409eff;
      color: #409eff;
    }
  }

  .cf-input {
    &.gap {
      margin-bottom: 12px;
    }
  }
}
</style>
