import request from "@/utils/request";

/**
 * Get schedule.
 *
 * @param {number} schedule_id
 * @param {boolean} relation
 */
export function getSchedule(schedule_id, relation = false) {
  return request({
    url: `api/v1/schedules/${schedule_id}?relation=${relation}`,
    method: "GET",
  });
}

/**
 * Get schedules with pagination and search features.
 *
 * @param {object} [params]
 * @param {number} params.limit
 * @param {number} params.page
 * @param {string} [params.search]
 */
export function getSchedulesPagination(params) {
  return request({
    url: "api/v1/schedules/page",
    method: "GET",
    params,
  });
}

/**
 * Create the schedule.
 *
 * @param {object} data
 * @param {string} data.name_zh
 * @param {string} data.name_en
 * @param {boolean} data.active
 * @param {string} data.start_date
 * @param {string} data.end_date
 * @param {string[]} data.cf_video_links
 * @param {string} [data.note]
 */
export function createSchedule(data) {
  return request({
    url: "api/v1/schedules",
    method: "POST",
    data,
  });
}

/**
 * Update the schedule.
 *
 * @param {number} schedule_id
 * @param {object} data
 * @param {string} data.name_zh
 * @param {string} data.name_en
 * @param {boolean} data.active
 * @param {string} data.start_date
 * @param {string} data.end_date
 * @param {string[]} data.cf_video_links
 * @param {string} [data.note]
 */
export function updateSchedule(schedule_id, data) {
  return request({
    url: `api/v1/schedules/${schedule_id}`,
    method: "PATCH",
    data,
  });
}

/**
 * Remove the schedule.
 *
 * @param {number} schedule_id
 */
export function removeSchedule(schedule_id) {
  return request({
    url: `api/v1/schedules/${schedule_id}`,
    method: "DELETE",
  });
}

/**
 * Get schedule's artists.
 *
 * @param {number} schedule_id
 * @param {object} params
 * @param {number} [params.limit]
 * @param {number} [params.page]
 * @param {string} [params.search]
 */
export function getArtists(schedule_id, params) {
  return request({
    url: `api/v1/schedules/${schedule_id}/artists`,
    method: "GET",
    params,
  });
}

/**
 * Add artist into schedule.
 *
 * @param {number} schedule_id
 * @param {string} artist_id
 */
export function addArtist(schedule_id, artist_id) {
  return request({
    url: `api/v1/schedules/${schedule_id}/artists/${artist_id}`,
    method: "POST",
  });
}

/**
 * Create artists into schedule.
 *
 * @param {number} schedule_id
 * @param {object} data
 * @param {string[]} data.artist_id_group
 */
export function addArtists(schedule_id, data) {
  return request({
    url: `api/v1/schedules/${schedule_id}/artists`,
    method: "POST",
    data,
  });
}

/**
 * Remove artist from schedule.
 *
 * @param {number} schedule_id
 * @param {string} artist_id
 */
export function removeArtist(schedule_id, artist_id) {
  return request({
    url: `api/v1/schedules/${schedule_id}/artists/${artist_id}`,
    method: "DELETE",
  });
}

/**
 * Get schedule's activities.
 *
 * @param {number} schedule_id
 * @param {object} params
 * @param {number} [params.limit]
 * @param {number} [params.page]
 * @param {string} [params.search]
 */
export function getActivities(schedule_id, params) {
  return request({
    url: `api/v1/schedules/${schedule_id}/activities`,
    method: "GET",
    params,
  });
}

/**
 * Add activity into schedule.
 *
 * @param {number} schedule_id
 * @param {string} activity_id
 */
export function addActivity(schedule_id, activity_id) {
  return request({
    url: `api/v1/schedules/${schedule_id}/activities/${activity_id}`,
    method: "POST",
  });
}

/**
 * Create activities into schedule.
 *
 * @param {number} schedule_id
 * @param {object} data
 * @param {number[]} data.activity_id_group
 */
export function addActivities(schedule_id, data) {
  return request({
    url: `api/v1/schedules/${schedule_id}/activities`,
    method: "POST",
    data,
  });
}

/**
 * Remove activity from schedule.
 *
 * @param {number} schedule_id
 * @param {string} activity_id
 */
export function removeActivity(schedule_id, activity_id) {
  return request({
    url: `api/v1/schedules/${schedule_id}/activities/${activity_id}`,
    method: "DELETE",
  });
}

/**
 * Get schedule's works.
 *
 * @param {number} schedule_id
 * @param {object} params
 * @param {number} [params.limit]
 * @param {number} [params.page]
 * @param {string} [params.search]
 */
export function getWorks(schedule_id, params) {
  return request({
    url: `api/v1/schedules/${schedule_id}/works`,
    method: "GET",
    params,
  });
}

/**
 * Add work into schedule.
 *
 * @param {number} schedule_id
 * @param {string} work_id
 */
export function addWork(schedule_id, work_id) {
  return request({
    url: `api/v1/schedules/${schedule_id}/works/${work_id}`,
    method: "POST",
  });
}

/**
 * Add works into schedule.
 *
 * @param {number} schedule_id
 * @param {string} data
 * @param {string[]} data.work_id_group
 */
export function addWorks(schedule_id, data) {
  return request({
    url: `api/v1/schedules/${schedule_id}/works`,
    method: "POST",
    data,
  });
}

/**
 * Remove work from schedule.
 *
 * @param {number} schedule_id
 * @param {string} work_id
 */
export function removeWork(schedule_id, work_id) {
  return request({
    url: `api/v1/schedules/${schedule_id}/works/${work_id}`,
    method: "DELETE",
  });
}

/**
 * Get schedule and keyword relationship.
 *
 * @param {string} schedule_id
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.limit
 * @param {string} params.search
 */
export function getScheduleKeywords(schedule_id, params) {
  return request({
    url: `api/v1/schedules/${schedule_id}/keywords`,
    method: "GET",
    params,
  });
}

/**
 * Create schedule and keyword relationship.
 *
 * @param {string} schedule_id
 * @param {object} data
 * @param {number[]} data.keyword_id_group
 */
export function createScheduleKeywords(schedule_id, data) {
  return request({
    url: `api/v1/schedules/${schedule_id}/keywords`,
    method: "POST",
    data,
  });
}

/**
 * Delete schedule and keyword relationship.
 *
 * @param {string} schedule_id
 * @param {number} keyword_id
 */
export function deleteScheduleKeywords(schedule_id, keyword_id) {
  return request({
    url: `api/v1/schedules/${schedule_id}/keywords/${keyword_id}`,
    method: "DELETE",
  });
}
