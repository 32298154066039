<template>
  <div class="schedule-work-table">
    <div class="container">
      <table-title>
        <p>檔期作品管理</p>

        <el-button @click="drawer_visible = true" type="success"
          >新增作品</el-button
        >
      </table-title>

      <el-table :data="data" v-loading="loading">
        <el-table-column label="ID" prop="work_id" sortable></el-table-column>
        <el-table-column
          label="中文標題"
          prop="work_zh.title"
          sortable
        ></el-table-column>
        <el-table-column
          label="英文標題"
          prop="work_en.title"
          sortable
        ></el-table-column>

        <el-table-column label="編輯" width="180">
          <template v-slot="{ row }">
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-setting"
              @click="
                $router.push({
                  name: 'DetailWork',
                  params: { id: row.work_id },
                })
              "
              >內容</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(row.work_id)"
              >刪除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-drawer
      title="新增作品"
      direction="rtl"
      size="600px"
      :visible.sync="drawer_visible"
      :before-close="handleCloseCreateDrawer"
    >
      <div class="work-wrapper">
        <el-table
          ref="tableRef"
          :data="drawer_works"
          @select="handleSelect"
          @select-all="handleSelect"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
            prop="work_zh.title"
            label="中文標題"
          ></el-table-column>
          <el-table-column
            prop="work_en.title"
            label="英文標題"
          ></el-table-column>
        </el-table>

        <div class="flex-end" style="margin-top: 20px">
          <el-button type="primary" @click="handleSubmit">確定</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { removeWork, addWorks } from "@/api/schedule";
import { getWorksPagination } from "@/api/work";

export default {
  name: "ScheduleWorkTable",
  props: {
    data: {
      required: true,
      type: Array,
      default: () => [],
    },
    loading: {
      required: true,
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      drawer_visible: false,
      drawer_works: [],
      setup: {
        page: 0,
        limit: 200,
      },
      current_selection: [],
    };
  },
  watch: {
    async drawer_visible(val) {
      if (val) {
        await this.handleGetWorksPagination();

        this.$nextTick(() => {
          this.data.forEach(({ work_id }) => {
            this.$refs.tableRef.toggleRowSelection(
              this.drawer_works.find(({ work_id: id }) => id === work_id),
              true
            );
          });
        });
      }
    },
  },
  computed: {
    scheduleId() {
      return +this.$route.params.id;
    },
  },
  methods: {
    async handleGetWorksPagination() {
      const { results } = await getWorksPagination(this.setup);

      this.drawer_works = results;
    },
    async handleDelete(workId) {
      this.$confirm("是否刪除作品", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await removeWork(this.scheduleId, workId);

          this.$message({
            type: "success",
            message: "刪除成功",
          });

          this.$emit("get:works");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "刪除取消",
          });
        });
    },
    handleCloseCreateDrawer(done) {
      this.$confirm("確認關閉新增抽屜？", "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    handleSelect(selection) {
      this.current_selection = selection.map(({ work_id }) => work_id);
    },
    async handleSubmit() {
      await addWorks(this.scheduleId, {
        work_id_group: this.current_selection,
      });

      this.$message({
        type: "success",
        message: "修改成功",
      });

      this.$emit("get:works");

      this.drawer_visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-work-table {
  margin-bottom: 20px;

  .activity-wrapper {
    padding: 0 10px 20px;

    .button-block {
      margin-top: 20px;
    }
  }
}
</style>
