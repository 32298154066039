var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"schedule-artist-table"},[_c('div',{staticClass:"container"},[_c('table-title',[_c('p',[_vm._v("檔期藝術家管理")]),_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){_vm.drawer_visible = true}}},[_vm._v("新增藝術家")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.data}},[_c('el-table-column',{attrs:{"label":"圖像","prop":"avatar","width":"150","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-avatar',{staticStyle:{"object-position":"center center"},attrs:{"size":60,"fit":"cover","src":_vm.handleAvatarUrl(row.avatar)}})]}}])}),_c('el-table-column',{attrs:{"label":"中文名稱","prop":"artist_zh.name","sortable":""}}),_c('el-table-column',{attrs:{"label":"英文名稱","prop":"artist_en.name","sortable":""}}),_c('el-table-column',{attrs:{"label":"編輯","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"mini","type":"warning","icon":"el-icon-setting"},on:{"click":function($event){return _vm.$router.push({
                name: 'DetailArtist',
                params: { id: row.artist_id },
              })}}},[_vm._v("內容")]),_c('el-button',{attrs:{"size":"mini","type":"danger","icon":"el-icon-delete"},on:{"click":function($event){return _vm.handleDelete(row.artist_id)}}},[_vm._v("刪除")])]}}])})],1)],1),_c('el-drawer',{attrs:{"title":"新增藝術家","direction":"rtl","size":"600px","visible":_vm.drawer_visible,"before-close":_vm.handleCloseCreateDrawer},on:{"update:visible":function($event){_vm.drawer_visible=$event}}},[_c('div',{staticClass:"artist-wrapper"},[_c('el-table',{ref:"tableRef",attrs:{"data":_vm.drawer_artists},on:{"select":_vm.handleSelect,"select-all":_vm.handleSelect}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"prop":"artist_zh.name","label":"中文名稱"}}),_c('el-table-column',{attrs:{"prop":"artist_en.name","label":"英文名稱"}})],1),_c('div',{staticClass:"flex-end",staticStyle:{"margin-top":"20px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("確定")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }